import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Gantt from "frappe-gantt";
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Project } from 'app/models/project-model';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { InitialiseService } from './initialise.service';
import { OpenBooksService } from './open-books.service';
import { NotificationService } from './notification.service';
import { Stand } from 'app/models/map';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StandDetailsModalComponent } from 'app/views/public-clients/nyeredzi-map/stand-details-modal/stand-details-modal.component';
import { StandFilterModalComponent } from 'app/views/public-clients/nyeredzi-map/stand-filter-modal/stand-filter-modal.component';
import { ReserveModalComponent } from 'app/views/public-clients/nyeredzi-map/reserve-modal/reserve-modal.component';

import Map from "ol/Map";
import View from "ol/View";
import XYZ from "ol/source/XYZ.js";
import KML from "ol/format/KML.js";
import TileLayer from "ol/layer/Tile";
import { fromLonLat, transform } from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Circle as CircleStyle, Fill, Text, Style, Stroke } from "ol/style.js";
import Feature from "ol/format/Feature";
import { Select } from "ol/interaction";
import { Point, Polygon } from "ol/geom";
import { format } from "ol/coordinate";
// import { Stand } from '../model/stand';
import Source from "ol/source/Source";

@Injectable({
  providedIn: 'root'
})

export class PublicService {
  prDocRef: Observable<any>;
  setProject: any;
  acc: any;
  dailyplanTasks: any[];
  compostTaskList: any[];
  projectWorkPlan: any[];
  allsections: any[];
  sections: any[];
  rates: any[];
  team: any[];
  photoData: any[];
  tasksList: any[];
  projectTeam;
  companyData: any[];
  company;
  user;
  setStreamDoc: {
    by: {},
    link: { no: number, name: string, src: string },
    date: string,
    no: number
  }
  streamCollection: { by: {}, link: { no: number, name: string, src: string }, date: string, no: number }[] = [];
  playTag: { pigFwd: boolean, pigBack: boolean };
  ent: any;
  rootProject: any;
  invData: any[];
  results: any[];
  dataShown: string;
  value: any;
  setInventory;
  public admin = false;
  itemNo: number;
  rawData: any[];
  dataTable: { headerRow: string[]; footerRow: string[]; dataRows: any[]; };
  barData: any[];
  searchDft;
  selected: any[];
  progressData: any[];
  counter: number;
  tasks: any;
  frappeTasks: any[] = [];
  teamSections: any[] = [];
  freppData: any[];
  projs = [
    { name: 'Nyeredzi Ridge', id: 'itnHEizBd3Yf7IAnRdPm'},
    { name: 'CAD', id: '6CvJkB7ylK67BNZgxMbt'}
  ];
  projects: any[];
  jstasks: any[] = [];
  pubTable: any[] = [];
  gantt: any;
  gunttTasks: any[] = [];
  guntterTasks: any[];
  key: any;
  namy: any;
  mapData: Stand[] = [];
  filter: any;
  originalMap: any;
  stand: any;
  modalService: any;
  standsMap: any;
  footerUpdate: { stand: any; event: string; coordinates: any; };
  data: any;
  standsSourceHidden: any | undefined;
  standsLayerHidden: VectorLayer<any> | undefined;
  standsSource: any;
  standsLayer: VectorLayer<any> | undefined;
  map: Map | undefined;
  invObs: Observable<any[]>;

  constructor(modalService: NgbModal, public afs: AngularFirestore, public storage: AngularFireStorage, public afAuth: AngularFireAuth, public is: InitialiseService, public ns: NotificationService) { /*  public ob: OpenBooksService */
    // this.getPubProjects().subscribe(pjs => {
    //   console.log(pjs?.length, pjs);
    //   this.projects = pjs;
     //   console.log(pjs, this.projects);
      // console.log(this.setProject);
      afAuth.authState.subscribe(user => {
        if (user === null) {
          this.getprojInfo();
        } else {
          this.acc = user;
          this.acc.name = user?.displayName;
          this.acc.id = user?.uid;
          this.getprojInfo();
          // this.doCalls();
          // console.log(user, user?.metadata?.creationTime);
        }
      // });
    //   console.log(this.is.ent);
      this.playTag = { pigFwd: false, pigBack: false };
      this.setInventory = is.initInventory();
      this.is.winDisplay();
    });
    // this.projects = this.projs;
  }

  toggleAdmin() {
    this.admin = !this.admin;
  }

  callReports() {
    this.afs.doc<any>(`Projects/${this.setProject?.id}/pubReports`).valueChanges().subscribe(rptRef => {
      if (rptRef) {

      }
    })
  }

  saveReport(rpt) {
    rpt.updatedBy = {
      // name: this.usd
    }
    this.afs.doc<any>(`Projects/${this.setProject?.id}/pubReports/${rpt.id}`).update(rpt).then(() => {

    });
  }

  addReport(rpt) {
    this.afs.collection<any>(`Projects/${this.setProject?.id}/pubReports`).add(rpt).then(() => {

    })
  }

  doCalls() {
    if (this.setProject && this.setProject?.id !== '') {
      this.afs.doc<any>(`Users/${this.acc.id}/projects/${this.setProject?.id}`).ref.get().then(prRef => {
        if (prRef.exists && prRef.data().companyId) {
          this.afs.doc<any>(`Users/${this.acc.id}/myenterprises/${prRef.data().companyId}`).ref.get().then(enTDoc => {
            if (enTDoc.exists && !enTDoc.data().hierarchy) {
              this.afs.doc<any>(`Enterprises/${enTDoc.data().id}/Participants/${this.acc.id}`).ref.get().then(doc => {
                // console.log('Getting User Data', doc.data());
                if (doc.exists) {
                  if (!doc.data().hierarchy) {
                    this.acc.hierarchy = 'Operations'
                  } else {
                    this.acc.hierarchy = doc.data().hierarchy;
                  }
                }
              })
            }
          })
          if ((!this.ent || !this.ent.id || this.ent.id === '') && (prRef.data().companyId && prRef.data().companyId !== '')) {
            this.afs.doc(`Projects/${this.setProject?.id}/enterprises/${prRef.data().companyId}`).valueChanges()
              .subscribe((team: any) => {
                const psn = team?.find(man => man.id === this.acc.id);
                if (!this.acc.booksRole) {
                  this.acc.booksRole = psn.booksRole;
                }
                this.ent = team;
              });
          }
        }
      })
    }
  }

  saveStream(videoId) {
    const data = {
      by: this.acc,
      link: { no: this.streamCollection.length || 0, src: videoId },
      date: new Date().toISOString()
    }
    const colList = this.afs.collection<any>(`Projects/${this.setProject?.id}/streamCollection`);
    colList.add(data).then(ref => {
      const id = ref.id;
      colList.doc(id).update({ 'id': id })
    })
  }

  getStreams() {
    this.afs.collection<any>(`Projects/${this.setProject?.id}/streamCollection`).valueChanges().subscribe(streams => {
      for (let i = 0; i < streams.length; i++) {
        // const element = streams[i];
        streams[i].no = i + 1;
        streams[i].link.no = i + 1;
      }
      this.setStreamDoc = streams[0];
      this.streamCollection = streams;
    });
  }

  getprojInfo() {
    if (this.setProject?.id && this.setProject?.id !== '') {
 // console.log(this.setProject?.id);
      this.prDocRef = this.afs.doc<any>(`Projects/${this.setProject?.id}`).valueChanges();
      this.getProDetails();
    } else {
      this.getPubProjects();
    }
  }

  getPubProjects() {
    // return this.afs.collection<any>(`Projects`, ref => ref.where('pub', '==', true)).valueChanges();
    // this.projects = 
    this.afs.collection<any>('Projects', ref => ref.where('pub', '==', 'true')).valueChanges().subscribe(pjs => {
      // console.log(pjs?.length, pjs);
      this.projects = pjs;
      this.setProject = pjs?.length >= 1 ? this.projects[0] : null;
      this.prDocRef = this.afs.doc<any>(`Projects/${this.setProject?.id}`).valueChanges();
      this.getProDetails();
      // console.log(this.setProject);
      this.projects = pjs;
    })
  }
  
  setProjPub(id, state) {
    return this.afs.doc<any>(`Projects/${id}`).update({'pub': state}).then(() => {
 // console.log(`State updated ${state}`);
    });
  }

  getObData() {
    this.queryInv();
  }

  renamePhases(descr: string) {
    let legalDescription = '';
    if (descr.includes('Phase 2 of Stand 655 Pomona Township')) {
      legalDescription = descr.split('Phase 2 of Stand 655 Pomona Township').join('Stand 655 of Pomona Township');
    } else if (descr.includes('Phase 2 of Stand 662 Pomona Township')) {
      legalDescription = descr.split('Phase 2 of Stand 662 Pomona Township').join('Stand 662 of Pomona Township');
    } else if (descr.includes('Phase 2 of Stand 655 of Pomona Township')) {
      legalDescription = descr.split('Phase 2 of').join('');
    } else if (descr.includes('Phase 2 of Stand 662 of Pomona Township')) {
      legalDescription = descr.split('Phase 2 of').join('');
    } else {
      legalDescription = descr;
    }
    return legalDescription
  }

  sortInv(inv: any[], call) {
    let data = [];
    switch (call) {
      case 'productNo1st': {
        //statements; 
        data = inv.sort((a, b) => a.productNo.name - b?.productNo.name);
        break;
      }
      case 'productNolst': {
        //statements; 
        data = inv.sort((a, b) => b.productNo.name - a.productNo.name);
        break;
      }
      case 'productNoTyp': {
        //statements; 
        data = inv.sort((a, b) => a?.type?.value - b.type?.value);
        break;
      }
      case 'Date1st': {
        //statements; 
        data = inv.sort((a, b) => a?.createdOn.localeCompare(b?.createdOn));
        break;
      }
      case 'Datelst': {
        //statements; 
        data = inv.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
        break;
      }
      default: {
        //statements; 
        data = inv.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
        break;
      }
    }
    return data;
  }

  getInitials(name) {
    const result = [...name].filter((c, k, arr) => c !== ' ' && (k == 0 || arr[k - 1] == ' '))
    return result.toString().replace(/,/g, '');
  }

  queryInv() {
    const invFnd = this.invData?.length > 0 && this.invData[0].project.id === this.setProject.id ? this.invData.findIndex(x => x.project.id === this.setProject.id) : -1;
    // console.log(invFnd, this.invData?.length);
    if((invFnd === -1) && ((this.invData?.length === 0) || (this.invData?.length === undefined))) {
      this.invData = [];
      this.invObs = this.afs.collection<any>(`Projects/${this.setProject?.id}/inventory`).valueChanges();
      this.invObs.subscribe(inv => {
        this.invData = inv;
      })
    } else if((invFnd === -1) && (this.invData?.length > 0)){
 // console.log('Not found');
      this.invData = [];
      this.invObs = this.afs.collection<any>(`Projects/${this.setProject?.id}/inventory`).valueChanges();
      this.invObs.subscribe(inv => {
        this.invData = inv;
      })
    } else {
 // console.log('found');
    }
  }

  getTeams() {
    this.afs.collection(`Projects/${this.setProject?.id}/enterprises`, ref => ref.orderBy('name', 'asc')).valueChanges()
      .subscribe((team: any) => {
        this.counter = 0;
        for (let i = 0; i < team.length; i++) {
          let element = team[i];
          this.getRates(element, i);
          // element = this.getCmpAllSecs(element);
          if (i == team.length-1) {
            // console.log(team);
          // this.doTaskCalcs();
          }
          // console.log('section', element?.sections?.length, 'tasks', element?.tasks?.length,'rates', element?.pRates?.length);
        }
        this.team = team;
      });
  }

  getTeam(cmp) {
    const teamRef = this.team?.find(c => c.id === cmp.id);
    if (teamRef) {
      this.projectTeam = teamRef;
    }
  }

  allSelected() {
    console.log(this.setProject);
    this.results = [];
    this.getprojInfo();
  }

  getProDetails() {
    if (this.setProject && this.setProject?.id !== '') {
      this.callFunction(); this.getObData();
    } else {
      this.prDocRef.subscribe(prj => {
        this.setProject = prj; this.callFunction(); this.queryInv();
   // console.log(this.setProject);
      })
    }
  }

  availableInv() {
    // console.log('show available Inv');
    this.dataShown = 'Available';
    if (this.setProject?.id) {
      this.results = this.invData?.filter(inv => inv.approved.status === true && (!inv?.SellStatus?.status || inv?.SellStatus?.status !== 'Sold'));
      // this.results.forEach(element => {
      //   element.parent = this.renamePhases(element.parent);
      //   element.name = element.type.value + ' ' + String(element.productNo.name) + ' of ' + element?.parent;
      //   element.description = element.name;
      // });
      this.callphased();
    } else {
      this.getprojInfo();
      setTimeout(() => this.availableInv(), 300);
    }
  }

  callphased() {
    this.results.forEach(element => {
      element.parent = this.renamePhases(element.parent);
      element.name = element.type.value + ' ' + String(element.productNo.name) + ' of ' + element?.parent;
      element.description = element.name;
    });
  }

  searchProperty(n) {
    this.dataShown = 'Search';
    // console.log(n);
    // const word = String(this.value);
    const word = this.value;
    if (n === 'size') {
      const words = [];
      this?.invData?.forEach(inv => {
        if (inv?.size.name === word) {
          words.push(inv);
          if (word && word.length > 1) {
            this.results = words.sort((a,b) => a?.size?.name - b?.size?.name);
            this.callphased();

          } else {
            this.results = [];
          }
        } else if (String(inv?.size?.name)?.includes(word)) {
          words.push(inv);
          if (word && word.length > 1) {
            this.results = words.sort((a,b) => a?.size?.name - b?.size?.name);
            this.callphased();
          } else {
            this.results = [];
          }
        }
      });
      // console.log(words);
    } else {
      const words = this?.invData?.filter(wor => ((wor?.name?.toLowerCase())?.includes(word?.toLowerCase())));
      // console.log(words);
      if (word && word.length > 1) {
        this.results = words.sort((a,b) => a?.productNo?.name - b?.productNo?.name);
        this.callphased();
      } else {
        this.results = [];
      }
    }
  }

  callFunction() {
    this.getStreams();
    // this.getprojTasks();
    this.photoFeed();
    this.getTeams();
  }

  photoFeed() {
    this.afs.collection(`Projects/${this.setProject?.id}/photofeed`).valueChanges().subscribe(picArr => {
      // console.log(picArr.length);
      this.photoData = picArr;
    })
  }

  getRates(company, i) {
    // console.log(company?.name, i);
    this.afs.collection(`Projects/${this.setProject?.id}/enterprises/${company.id}/Rates`, ref => ref.orderBy('name', 'asc'))
      .valueChanges().subscribe((rates: any[]) => {
        // console.log(company?.name, i, rates);
        const cmpIndex = this.team?.findIndex((cmp: any) => cmp.id === company.id);
        if (cmpIndex > -1) { this.team[cmpIndex].pRates = rates; }
        if (!this.rates) {
          this.rates = rates;
          // console.log(this.rates?.length, this.rates);
          this.counter += 1;
        } else {
          this.rates = this.rates.concat(rates);
          // console.log(this.rates?.length, this.rates);
          this.counter += 1;
        }
        // console.log(this.counter, this.team.length);
        if (this.counter <= this.team.length) {
          // console.log(this.counter, this.team.length);
        }
        if(i === this.team?.length -1) {
          // console.log(this.team);
          this.getprojTasks();
        }
      });
  }

  // onlyPj() {
  //   return 'itnHEizBd3Yf7IAnRdPm'; // nyeredzi ridge project
  //   // return '6CvJkB7ylK67BNZgxMbt'; // CAD project
  // }
  

  availablePjs() {
    const arr = [{ name: 'Nyeredzi Ridge', id: 'itnHEizBd3Yf7IAnRdPm'}, { name: 'CAD', id: '6CvJkB7ylK67BNZgxMbt'}];
    return arr; // nyeredzi ridge project
    // return '6CvJkB7ylK67BNZgxMbt'; // CAD project
  }

  returnTasks(id) {
    return this.afs.collection(`tasks`, ref => ref.where('projectId', '==', id)).valueChanges();
  }

  async getprojTasks() {
    this.dailyplanTasks = [];
    Promise.all(this.getCmpSections()).then(refData => {
      if (this.setProject?.id && this.setProject?.id !== '') {
        // console.log(this.setProject.id, refData);
        this.returnTasks(this.setProject?.id).subscribe((Tasks: any[]) => {
          // console.log('getprojTasks', Tasks.length)
          if (Tasks.length > 0) {
            this.compostTaskList = Tasks;
            this.dailyplanTasks = Tasks;
            this.projectWorkPlan = Tasks;
          }
    //       let filteredArray = Tasks.reduce((acc, item) => {
    //         if (refData.some(filterItem =>  (filterItem.sectionId === item?.section?.sectionId || filterItem.sectionId === item?.section?.id))) {
    //             acc.push(item);
    //         }
    //         return acc;
    //       }, []);
    //  // console.log(filteredArray);
    //       const dads = filteredArray;
          
          // for (let i = 0; i < dads.length; i++) {
          //   const el = dads[i];
          //   const sectionfnd = refData.find(sec => (sec.sectionName === el?.section?.name || sec.name === el?.section?.name) && sec.No !== undefined);
          //   // sectionfnd ?? console.log('All Tasks', el?.section, sectionfnd, refData, refData?.length);
          //   el.section = sectionfnd ? sectionfnd : el?.section;
          //   el.tasksObs = this.afs.collection(`tasks/${el.id}/actionActuals`).valueChanges();
          //   this.afs.collection(`tasks/${el.id}/actionActuals`).valueChanges().subscribe((data: any[]) => {
          //     if (data.length > 0) {
          //       el.actionActuals = data;
          //       data.filter(wrk => moment(wrk.updateTime).isBefore(moment().subtract(1, 'days'))).forEach(value => {
          //         if (!el.actsSumyst) { el.actsSumyst = value.qty | 0 } else {
          //           el.actsSumyst += value.qty | 0;/*  console.log(el.description, el.actsTotal , el.actsSumyst); */
          //         }
          //       });
          //     }
          //   })
          //   if (dads.length === (i + 1)) {
          //     this.compostTaskList = dads;
          //     this.dailyplanTasks = dads;
          //     this.projectWorkPlan = Tasks;
          //   }
          // }
          this.progressStatus();
        })
      }
    });
  }

  freppeChart(tasks: any[]) {
    // console.log(tasks);
    this.tasks = [];
    
    const defTasks = tasks?.filter((tsk) => (tsk.start && tsk.start !== 'Invalid date') && (tsk.finish && tsk.finish !== 'Invalid date'));
    const sTasks = defTasks?.filter((tsk) => tsk.start).sort((a, b) =>moment(a?.start,'L').format('YYYY/MM/DD').localeCompare(moment(b?.start,'L').format('YYYY/MM/DD')));
    for (let index = 0; index < sTasks.length; index++) {
      const element: any = sTasks[index];
      if (element.start !== 'Invalid date' && element.finish !== 'Invalid date') {
        const act: any = {
          id: `Task ${index}`,
          name: element?.name || element?.description,
          start: moment(element?.start, "L").isBefore(moment(element?.finish, "L")) ? moment(element?.start, "L").format("YYYY-MM-DD"): moment(element?.finish, "L").format("YYYY-MM-DD"),
          end: moment(element?.finish, "L").isAfter(moment(element?.start, "L")) ? moment(element?.finish, "L").format("YYYY-MM-DD") : moment(element?.start, "L").format("YYYY-MM-DD"),
          progress: element.actual,
        }
        // if (index > 0) {
        //   var randomNumber = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
        //   act.progress = randomNumber;
        //   // console.log(act);
        // }
        if (act.start !== 'Invalid date' && act.end !== 'Invalid date') {
          this.tasks.push(act);
          // console.log(this.tasks);
          // this.tasks = this.tasks.sort((a, b) => a?.start?.localeCompare(b?.end));
        }
      }
    }
  }

  jsChart(tasks: any[]) {
    let jstasks = [];
    const defTasks = tasks?.filter((tsk) => (tsk.start && tsk.start !== 'Invalid date') && (tsk.finish && tsk.finish !== 'Invalid date'));
    const defSection = defTasks?.filter((tsk) => (tsk?.section?.name && tsk?.section?.name) !== '');
    let sTasks = defSection?.filter((tsk) => tsk.start);
    // sTasks = sTasks.sort((a, b) => a.No - b.No);
    const secTasks = sTasks.sort((a, b) => parseFloat(a.section.No) - parseFloat(b.section.No));
    // console.log('jsChart', tasks?.length, secTasks);
    for (let v = 0; v < secTasks.length; v++) {
      const element: any = sTasks[v];
      const start = moment(element?.start, "L").format("MM/DD/YYYY") !== 'Invalid date'? moment(element?.start, "L").format("MM/DD/YYYY"): moment(element?.start, "YYYY/MM/DD").format("MM/DD/YYYY");
      const finish = moment(element?.finish, "L").format("MM/DD/YYYY") !== 'Invalid date' ? moment(element?.finish, "L").format("MM/DD/YYYY") : moment(element?.finish, "YYYY/MM/DD").format("MM/DD/YYYY");
      const data = { 
        // stage: element?.section?.name ? element?.section?.name : 'No Stage' + element?.section.No ? element?.section.No : element?.type === "subSection" ? String(element?.section?.sectionNo)+'.'+String(element?.section?.sectionNo): 'none',
        stage:(element?.section?.name ? element?.section?.name : element?.name + element?.section.No ? element?.section.No : element?.type === "subSection" ? String(element?.section?.sectionNo)+'.'+String(element?.section?.sectionNo): 'none') +' '+element?.section.No,
        substage: element?.description? element?.description : element?.name || 'No name',
        dates: [moment(start, "MM/DD/YYYY").isBefore(moment(finish, "MM/DD/YYYY")) ? start: finish, moment(finish, "MM/DD/YYYY").isAfter(moment(start, "MM/DD/YYYY")) ? finish: start],
        complete: element.actualPrg || 0
      };
      jstasks.push(data);
      // if (jstasks?.length === 1) { data.id = '1'; }
      // console.log(jstasks);
      if (v == sTasks?.length -1) {
        let uniqueArray = jstasks.filter((v,i,a)=>a.findIndex(t=>(t.phase === v.phase))===i);
        jstasks = jstasks.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i);
        var groupByStage = this.groupTasks(jstasks);
        let newZvinhu = [];
        for (let j = 0; j < uniqueArray.length; j++) {
          const im = uniqueArray[j];
          const str = im.phase as keyof typeof groupByStage;
          let value = groupByStage[str]; // This will give you the value associated with the "OK" key
          value = value.sort((a, b) =>moment(a?.dates[0],'L').format('YYYY/MM/DD').localeCompare(moment(b?.dates[0],'L').format('YYYY/MM/DD')));
          for (let m = 0; m < value.length; m++) {
            newZvinhu.push(value[m]);
          }
          if (j === uniqueArray.length-1) {
            this.jstasks = newZvinhu.map(element => {
              return {id: element.id, phase: element.phase, name: element.name, start: element.start, end: element.end, parent: element.parent ? element.parent : ''};
            })
          }
        }
      }
      // this.jstasks = jstasks.sort((a, b) => a?.dates[0]?.localeCompare(b?.dates[0]));
      // this.jstasks = jstasks;
    }
  }

  jsGuntt(tasks: any[]) {
    // console.log('gunttChart', tasks?.length);
    let jstasks = [];
    const defTasks = tasks?.filter((tsk) => (tsk.start && tsk.start !== 'Invalid date') && (tsk.finish && tsk.finish !== 'Invalid date'));
    const defSection = defTasks?.filter((tsk) => ((tsk?.sectionName || tsk?.section?.name) && ((tsk?.sectionName || tsk?.section?.name) !== '')));
    // let sTasks = defSection?.filter((tsk) => tsk.start).sort((a, b) => b?.start?.localeCompare(a?.start));
    let sTasks = defSection?.filter((tsk) => tsk.start).sort((a, b) =>moment(a?.start,'L').format('YYYY/MM/DD').localeCompare(moment(b?.start,'L').format('YYYY/MM/DD')))
    // sTasks = sTasks.sort((a, b) => a.section.No - b.section.No);
    for (let index = 0; index < sTasks.length; index++) {
      const element: any = sTasks[index];
      if (element.start !== 'Invalid date' && element.finish !== 'Invalid date') {
        
        const start = moment(element?.start, "L").format("MM/DD/YYYY") !== 'Invalid date'? moment(element?.start, "L").format("MM/DD/YYYY"): moment(element?.start, "YYYY/MM/DD").format("MM/DD/YYYY");
        const finish = moment(element?.finish, "L").format("MM/DD/YYYY") !== 'Invalid date' ? moment(element?.finish, "L").format("MM/DD/YYYY") : moment(element?.finish, "YYYY/MM/DD").format("MM/DD/YYYY");
        const data: any = { 
          name: element?.name || element?.description,
          y: [
              moment(start, "MM/DD/YYYY").isBefore(moment(finish, "MM/DD/YYYY")) ? start: finish,
              moment(finish, "MM/DD/YYYY").isAfter(moment(start, "MM/DD/YYYY")) ? finish: start,
              // moment(element?.start, "L").format("MM/DD/YYYY") !== 'Invalid date'? moment(element?.start, "L").format("MM/DD/YYYY") : moment(element?.start, "YYYY/MM/DD").format("MM/DD/YYYY"),
              // moment(element?.finish, "L").format("MM/DD/YYYY") !== 'Invalid date' ? moment(element?.finish, "L").format("MM/DD/YYYY") : moment(element?.finish, "YYYY/MM/DD").format("MM/DD/YYYY")
            ],
          id: element?.id
        };
        if(index > 1){
          data.parent = sTasks[index-1].id
        }
        jstasks.push(data);
        // this.gunttTasks = jstasks.sort((a, b) => b?.y[0]?.localeCompare(a?.y[0]));
        // this.gunttTasks = jstasks.sort((a, b) =>moment(a?.y[0],'L').format('YYYY/MM/DD').localeCompare(moment(b?.y[0],'L').format('YYYY/MM/DD')));
        this.gunttTasks = jstasks;
      }
    }
  }

  jsGuntter(tasks: any[]) {
    // console.log('gunttChart', tasks?.length);
    let jstasks = [];
    const defTasks = tasks?.filter((tsk) => (tsk.start && tsk.start !== 'Invalid date') && (tsk.finish && tsk.finish !== 'Invalid date'));
    const defSection = defTasks?.filter((tsk) => ((tsk?.section?.name || tsk?.sectionName) && ((tsk?.section?.name || tsk?.sectionName) !== '')));
    let sTasks = defSection?.filter((tsk) => tsk.start).sort((a, b) => b?.start?.localeCompare(a?.start));
    sTasks = sTasks.sort((a, b) => parseFloat(a.section.No) - parseFloat(b.section.No));
    for (let index = 0; index < sTasks.length; index++) {
      const element: any = sTasks[index];
      // console.log(element.section?.name, element.section.No);
      if (element.start !== 'Invalid date' && element.finish !== 'Invalid date') {
        const start = moment(element?.start, "L").format("MM/DD/YYYY") !== 'Invalid date'? moment(element?.start, "L").format("MM/DD/YYYY"): moment(element?.start, "YYYY/MM/DD").format("MM/DD/YYYY");
        const finish = moment(element?.finish, "L").format("MM/DD/YYYY") !== 'Invalid date' ? moment(element?.finish, "L").format("MM/DD/YYYY") : moment(element?.finish, "YYYY/MM/DD").format("MM/DD/YYYY");
        const data: any = { 
          phase: (element?.section?.name ? element?.section?.name : element?.name + element?.section.No ? element?.section.No : element?.type === "subSection" ? String(element?.section?.sectionNo)+'.'+String(element?.section?.sectionNo): 'none') +' '+element?.section.No,
          name: element?.description ? element?.description : element?.name || 'No name',
          start: moment(start, "MM/DD/YYYY").isBefore(moment(finish, "MM/DD/YYYY")) ? start: finish, end: moment(finish, "MM/DD/YYYY").isAfter(moment(start, "MM/DD/YYYY")) ? finish: start, secNo: element?.section.No
        };
        // console.log(data.phase, data.name);
        jstasks.push(data);
        if (jstasks?.length === 1) { data.id = '1'; }
        // console.log(jstasks);
        if (index == sTasks?.length -1) {
          let uniqueArray = jstasks.filter((v,i,a)=>a.findIndex(t=>(t.phase === v.phase))===i);
          jstasks = jstasks.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i);
          var groupByStage = this.groupTasks(jstasks);
          let newZvinhu = [];
          for (let j = 0; j < uniqueArray.length; j++) {
            const im = uniqueArray[j];
            const str = im.phase as keyof typeof groupByStage;
            let value = groupByStage[str]; // This will give you the value associated with the "OK" key
            value = value.sort((a, b) =>moment(a?.start,'L').format('YYYY/MM/DD').localeCompare(moment(b?.start,'L').format('YYYY/MM/DD')));
            for (let m = 0; m < value.length; m++) {
              if (m === 0) {
                value[m].id = String(j + 1); value[m].name = value[m].phase;
              } else { value[m].id = String(j+1)+'.'+String(m);
                value[m].parent = value[m-1].id;
              }
              newZvinhu.push(value[m]);
            }
            if (j === uniqueArray.length-1) {
              this.guntterTasks = newZvinhu.map(element => {
                return {id: element.id, phase: element.phase, name: element.name, start: element.start, end: element.end, parent: element.parent ? element.parent : ''};
              })
            }
          }
        }
      }
    }
  }

  groupTasks(jstasks){
    let groupByStage = jstasks.reduce((groupedTasks, task) => {
      const key = task.phase;
      if (!groupedTasks[key]) { groupedTasks[key] = []; }
      groupedTasks[key].push(task);
      return groupedTasks;
    }, []);
    return groupByStage;
  }
  
  sg (fndPhase, findInd) {
    return fndPhase.id+'.'+String(findInd+1);
  }

  sgg (fndPhase) {
    return fndPhase.id;
    // sg();
  }

  mainSections(sections, subs) {
    sections.forEach(element => {
      element.subSections = subs.filter(scs => scs.sectionName === element.name && scs.companyId === element.companyId);
      const secInd = element.subSections.findIndex(workClass => workClass.name === element.sectionName && workClass.companyId === element.companyId);
      if (secInd > -1) {
        element.subSections[secInd].sectionNo = sections[secInd].no;
        element.subSections[secInd].No = String(element.sectionNo + '.' + element.no);
      }
      // element.subSections[secInd].No = String(element.sectionNo + '.' + element.no);
    });
    return sections
  }

  getSectTasks(section) {
    if (section.id && section.type === 'subSection') {
      return this.afs.collection(`Enterprises/${section.companyId}/projects/${section.projectId}/subSections/${section.id}/workItems`).valueChanges();
    } else if (section.id && section.type === 'superSection') {
      return this.afs.collection(`Enterprises/${section.companyId}/projects/${section.projectId}/sections/${section.id}/workItems`).valueChanges();
    } else { return Observable.of([]) }
  }

  /* **
                              Company report
  */

  tableform(arr) {
    // console.log('tableform', arr?.length);
    const dataTable = {
      headerRow: ['Name', 'Start', 'Finish', 'Planned', 'Actual', 'Actions', 'Active'],
      footerRow: ['Name', 'Start', 'Finish', 'Planned', 'Actual', 'Actions', 'Active'],
      dataRows: []
    };

    for (let x = 0; x < arr.length; x++) {
      const tsk = arr[x];
      // console.log(tsk);
      // console.log(tsk?.name, tsk?.description);
      const ds = [tsk.name, tsk.start, tsk.finish, tsk.planned, tsk.actual, '', tsk.pubshow || 'false', tsk.id, tsk.cmpId, tsk.cmpName, tsk.desc];
      // console.log(ds);
      dataTable.dataRows.push(ds)
    }
    // arr.forEach(tsk => {
      // console.log(tsk?.startDate, tsk?.endDate);
      // c;
    // });
    this.dataTable = dataTable;
  }

  playVideo(item) {
    this.setStreamDoc = item;
  }

  nextPlay(act) {
    // console.log(act, 'no', this.setStreamDoc?.no,'no2', this.setStreamDoc?.link.no, 'length', this.streamCollection?.length);
    if (this.setStreamDoc?.no === 1 && this.streamCollection?.length === this.setStreamDoc?.no) {
      this.playTag.pigBack = false;
      this.playTag.pigFwd = false;
    } else if ((this.setStreamDoc?.no === 1) && (this.streamCollection?.length > this.setStreamDoc?.no)) {
      this.playTag.pigBack = false;
      this.playTag.pigFwd = true;
      this.docNav(act);
    } else if ((this.setStreamDoc?.no > 1) && (this.setStreamDoc?.no < this.streamCollection?.length)) { /* this.setStreamDoc?.no > 1 &&  */
      this.playTag.pigBack = true;
      this.playTag.pigFwd = true;
      this.docNav(act);
    } else if ((this.streamCollection?.length > 1) && (this.setStreamDoc?.no === this.streamCollection?.length)) {
      this.playTag.pigBack = true;
      this.playTag.pigFwd = false;
      this.docNav(act);
    } else { }
  }

  docNav(par) {
    if (((this.setStreamDoc?.no >= 1)) || (this.setStreamDoc?.no < this.streamCollection?.length)) {
        this.playData(par);
    }
  }

  playData(par) {
    const i = this.setStreamDoc?.no;
    const m = this.streamCollection?.length;
    if (par === 'bk' && i !== 1) {
      const x = i - 1;
      const fndVid = this.streamCollection.find(vid => vid.link.no === x);
      this.setStreamDoc = fndVid;
 // console.log(par, fndVid);
      this.playVideo(fndVid);
    } else if (par === 'fd' && i < m) {
      const x = i + 1;
      const fndVid = this.streamCollection.find(vid => vid.link.no === x);
      this.setStreamDoc = fndVid;
 // console.log(par, fndVid);
      this.playVideo(fndVid);
    } else { console.log(par); }
  }































// Get a company working on a project and its project sections
getCmpSections() {
  // console.log('getCmpSections', this.team);
  let allTasks = [];
  // for (let i = 0; i < this.team.length; i++) {
  //   const team = this.team[i];
    
  // }
  this.team?.forEach((team) => {
    if (this.team?.length === 1) {
 // console.log(team.sections);
      if (team.sections && team.sections.length > 0) {
        this.teamSections = team.sections;
      }
    } else {
      if (team?.sections) {
        // console.log(team.sections);
        team?.sections?.sort((a, b) => a.No - b.No);
        for (let index = 0; index < team?.sections?.length; index++) {
          const sec = team?.sections?.[index];
          const fndSect = this.teamSections.find(s => s.sectionNo === sec.no && this.team[0].id === s?.companyId);
          const fndSectInd = this.teamSections.findIndex(s => s.sectionNo === sec.no && this.team[0].id === s?.companyId);
          if (fndSect) {
            allTasks = allTasks.concat(sec.tasks);
       // console.log(allTasks);
            this.teamSections[fndSectInd].tasks = this.teamSections[fndSectInd].tasks.concat(sec.tasks);
          } else {
            this.teamSections.push(sec);
          }
          // this.teamSections.sort((a, b) => a.no - b.no);
          this.teamSections.sort((a, b) => a.No - b.No);
        }
      }
    }
  });
  const mySections = this.teamSections;
  return mySections;
}

getSectionz() {
  // const mySections = this.getCmpSections()?.filter(x => ((x.name === 'Implementation Stage' || x.sectionName === 'Implementation Stage') && ( x?.phase?.name !== 'Phase 2')));
  const mySections = this.getCmpSections();
  mySections.forEach(element => {
    element.phase = element.phase || { amount: '$0.00', createdBy: {}, id: 'phase1', name: 'Phase 1', createdOn: '', unit: '' };
  });
  return mySections;
}

  //! Get type of endDate in the task array










































































































  progressStatus() {
    console.log('progressStatus');
    this.tasksList = [];
    // Filter only implementation and it's subsections since we want to show type of tasks under implementation section
    const eTasks = this.getTasks();
    // console.log('getTasks', eTasks?.length);
    const Imp = this.getSectionz();
    // console.log(Imp?.length, Imp);
    eTasks?.forEach(element => {
      const secFnd = Imp.find(sec => sec.id === element?.section?.id && (sec?.companyName || sec?.company?.name) === (element?.companyName || element?.company?.name));
      if (!secFnd && element?.section?.name) {
        // console.log(element?.section?.name, element?.section);
        Imp.push(element.section);
      }
      // console.log(element.name, element?.section?.name, eTasks.filter(item => item?.section?.name && item?.section?.name !== '' && item?.section?.name !== undefined)?.length);
    });
    Imp?.forEach((element) => {
      if (element?.subSections && element?.subSections?.length > 0) {
        element.tasks = eTasks?.filter((task) => task.section?.name === element?.name && task.companyId === element.companyId)/*  New Query with a company */
        // console.log(element.name, element, element.tasks);
        for (let i = 0; i <= element?.subSections?.length; i++) {
          const sub = element?.subSections[i];
          const tasks = eTasks?.filter((task) => task.section?.name === sub?.name && task.companyId === element.companyId);
          element.tasks?.length > 0 ? element.tasks.concat(tasks): element.tasks = tasks;
          // console.log(element?.name, sub?.name, sub, sub?.tasks);
          if (i === element?.subSections?.length - 1) {
            element.tasks?.length > 0 ? this.getTaskActuals(element): 'No tasks';
          }
        }
      } else {
        element.tasks = eTasks?.filter((task) => task.section?.name === element?.name && task.companyId === element.companyId);
        element.tasks?.length > 0 ? this.getTaskActuals(element): 'No tasks';
      }
    });
    Promise.all(this.tasksList).then(p2 => {
      // console.log(p2?.length);
      this.teamsgrantchart(p2);
    })
  }
  
  getTaskActuals(sec) {
    // console.log('getTaskActuals', sec.name, sec.companyName, sec.tasks?.length);
    for (let x = 0; x < sec.tasks.length; x++) {
      const element = sec.tasks[x];
      const i = sec?.actions?.findIndex(task => (task?.name === element.name && task?.section?.name === sec.name && task?.companyId === sec.companyId));
      if (i == -1) {
        sec?.actions?.push(element); // ations planned tasks from the cost framework
      }
      if (x === sec.tasks?.length - 1) {
        // console.log(sec?.actions?.length);
        sec?.actions?.forEach((elem, i) => {
          //! Assign project phase to all type of tasks
          if (elem.quantity) { elem.targetQty = elem.quantity; };
          let newVal = 0;
          // let newVal2 = 0;
          elem.arrTasks = this.getTasks().filter(task => (task.name === elem.name && task?.section?.name === sec.name && task?.companyId === sec.companyId));
          elem.startDate = this.sortStart(elem.arrTasks);
          elem.endDate = this.endDate(elem.arrTasks);
          elem.phase = sec.phase;
          // console.log(elem.name, elem?.arrTasks);
          if (elem.arrTasks && elem.arrTasks?.length > 0) {
            elem.arrTasks.forEach(el => {
              if (!(el.actsTotal)) {
                el.actsTotal = 0;
              } else {
                el.actsTotal = el.actsTotal;
              }
              newVal += el.actsTotal;
              elem.actsTotal = newVal;
            });
          } // show phase of each type of task.
          this.getTypes(elem);
        });
      }
    }
  }

  getTasks() {
    const prJtasks = this.compostTaskList;
    prJtasks?.forEach((task: any, i) => {
      // console.log(i, task?.name);
      if (!task?.name) {
        // console.log(i, task?.name, task?.description);
      } else { }
    })
    return prJtasks;
  }
  
  sortStart(arrTasks): string {
    arrTasks?.length > 0 ? arrTasks = sortStartDates(arrTasks).sort((a, b) => {
      if (a?.startDate && b?.startDate) {
        return moment(a?.startDate).format('L').localeCompare(moment(b?.startDate).format('L'));
      }
    }): '' ;
  
    function sortStartDates(arrTasks) {
      arrTasks.forEach(element => {
        if (element.startDate) {
        } else if (!element.startDate || element.startDate === '') {
          element.startDate = moment(element.createdOn).format('L');
        }
      });
      return arrTasks;
    }
    return arrTasks?.length > 0 ? moment(arrTasks[0]?.startDate).format('L'): '';
  }
  
  endDate(arrTasks) {
    // let arrTasks = this.getTasks()
    arrTasks?.length > 0 ? arrTasks = sortEndDates(arrTasks).sort((a, b) => {
      if (b?.endDate && a?.endDate) {
        return moment(b?.endDate).format('L').localeCompare(moment(a?.endDate).format('L'));
      }
    }): '' ;
    function sortEndDates(arrTasks) {
      arrTasks.forEach(element => {
        if (element.endDate) {
        } else if (!element.endDate || element.endDate === '') {
          element.endDate = moment(element.update).format('L');
        }
      });
      return arrTasks;
    }
    return arrTasks?.length > 0 ? moment(arrTasks[0]?.endDate).format('L'): '';
  }
  
  getTypes(element) {
    const tskIndex = this.tasksList.findIndex(tsc => element.id === tsc.id);
    // const tsk = this.tasksList.find(tsc => element.id === tsc.id);
    if (tskIndex > -1) {
      if (typeof(element?.targetQty) === 'string' && element?.targetQty !== '' && Number(element?.targetQty) > 0) {
        // console.log(element?.targetQty, 'String', Number(element?.targetQty));
        this.tasksList[tskIndex].targetQty += Number(element?.targetQty) || 0;
      } else if (element?.targetQty) {
        // console.log(element?.targetQty);
        this.tasksList[tskIndex].actsTotal += element?.actsTotal || 0;
        this.tasksList[tskIndex].targetQty += element?.targetQty || 0;
      } else {
        // console.log(element?.targetQty);
      }
    } else {
      element.wRpt = element.actsTotal;
      const elem = element;
      // console.log(element, element.startDate, element.endDate);
      this.tasksList.push(elem);
    }
    this.tasksList.forEach((tasi: any) => {
      if (!tasi?.name) {
      } else {
        const cot = this?.rates?.find(t => t?.name?.toLowerCase() === tasi?.name?.toLowerCase());
        if (cot) {
          tasi.name = cot.name;
        }
      }
    })
  }
  
  // Gantt Chart Drawing Functions
  createData(dsData: any[]) {
    console.log(dsData.length);
    let typeOfTasks = [];
    let selected = [];
    let table = [];
    let equivalentDays: number;
    dsData?.forEach((element) => {
      // console.log(element.name, element?.phase?.name, element.id);
      // console.log(element.section);
      if (((element.actsTotal / element.targetQty) !== undefined && Number.isFinite(Number(element.actsTotal / element.targetQty)) && !Number.isNaN(Number(element.actsTotal / element.targetQty)))) {
        if (((element.actsTotal) && (element.actsTotal > element.targetQty || element.targetQty === '' || element.targetQty === undefined))) {
          element.targetQty = element.actsTotal;
          element.actualPrg = ((element.actsTotal / element.targetQty) * 100).toFixed(2);
        } else {
          element.actualPrg = ((element.actsTotal / element.targetQty) * 100).toFixed(2);
        }
      } else {
        element.actualPrg = 0;
      }
      const startDfn = this.sortStart(element.arrTasks);
      const endDfn = this.endDate(element.arrTasks);
      const tDae = moment().format("L");
      if ((moment(startDfn).isBefore(endDfn))) {
        element.Period = moment(endDfn).diff(moment(startDfn), "d");
      } else if ((moment(startDfn).isSame(endDfn))) {
        element.Period = 1;
      } else {
        element.Period = 0;
      }
  
      if ((moment(tDae).isSameOrAfter(startDfn)) && (moment(tDae).isSameOrBefore(endDfn)) && (moment(startDfn).isSameOrBefore(endDfn))) {
        element.ToDate = moment(tDae).diff(moment(startDfn), "d");
      } else if ((moment(tDae).isAfter(startDfn)) && (moment(tDae).isAfter(endDfn)) && (moment(startDfn).isSameOrBefore(endDfn))) {
        element.ToDate = moment(endDfn).diff(moment(startDfn), "d");
      } else {
        element.ToDate = 0;
      }
  
      if ((element.ToDate >= 1 && element.Period >= 1)) {
        element.plannedPrg = Math.round(100 * (element.ToDate / element.Period));
      } else if ((moment(startDfn).isSame(endDfn))) {
        element.plannedPrg = 100;
      } else {
        element.plannedPrg = 0;
      }
  
      if (element.name) {
        // console.log(element.id);
        const taski = {
          name: element?.name, description: element?.description, planned: element?.plannedPrg, actual: element?.actualPrg, start: startDfn, finish: endDfn, id: element.id, section: element.section
        };
        const getRate = this.rates?.find(rate => ((rate.name === element?.name) && (rate.companyId === element?.companyId)));
        if (getRate && getRate.pubshow) { element.id = getRate.id; element.pubshow = getRate.pubshow; /* console.log(element.name, element.pubshow); */ } else { element.pubshow = 'false'; /* console.log(element.name, element.pubshow); */ }
        const tks: any = {
          name: element?.name, description: element?.description, desc: element?.name, start: startDfn, finish: endDfn, planned: element?.plannedPrg, actual: element?.actualPrg, id: element.id, pubshow: element.pubshow, cmpId: element.companyId, cmpName: element.companyName, section: element.section
        };
        if (element.pubshow === 'true') {
          selected.push(taski)
          selected.filter((item, index) => selected.indexOf(item.name) === index); /* getting read of dublicates */
          this.selected = selected;
          // console.log(this.selected);
        }
        typeOfTasks.push(taski);
        table.push(tks);
        this.rawData = table;
      }
    });
    // let taskso = typeOfTasks.filter((item, index) => typeOfTasks.indexOf(item.name) === index);
    // console.log(this?.selected?.length, typeOfTasks?.length, this?.rawData?.length);
    return typeOfTasks;
  }
  //! team Gannt Chart Start
  teamsgrantchart(dsData) {
    // console.log(dsData?.length);
    const allTypes = this.createData(dsData);
    // console.log(allTypes);
    Promise.all(allTypes)
      .then((result) => {
        this.barData = result;
        const selected = result.filter(tks => tks.pubshow === 'true');
        console.log('selected', selected);
        console.log(dsData.length, allTypes.length);
        if (this.selected && this.selected.length > 0) {
          // console.log(this?.selected.length);
          Promise.all(this.selected).then(fndselected => {
            // console.log(`fndselected data ${fndselected}`);
            if (fndselected && fndselected?.length > 0) {/* && fndselected?.length > 0 */
              // console.log(`showing selected data ${fndselected}`);
              console.log(`fndselected data ${fndselected} ${fndselected?.length}`);
              this.itemNo = fndselected.length;
              this.jsChart(fndselected);
              this.freppeChart(fndselected);
              this.jsGuntt(fndselected);
              this.jsGuntter(fndselected);
              this.tableform(fndselected);
            } else {
              this.ns.showNotification('no-data', 'top', 'right', 'no-data');
            }
          }).catch(er => console.log("can't find anything", er));
        } else {
          console.log('result', result , result.length);
          this.itemNo = result.length;
          // this.dataCall(result);
          this.jsChart(result);
          this.freppeChart(result);
          this.jsGuntt(result);
          this.jsGuntter(result);
          this.tableform(result);
        }
      }).catch((error) => console.log(error));
  }
  
  ngOnInit() {
    if (!this.acc?.name) {
      this.afAuth.authState.subscribe(user => {
        if (user === null) { } else {
          this.acc = user;
          this.acc.name = user?.displayName;
          this.acc.id = user?.uid;
        }
      });
    }
    Promise.all(this.compostTaskList).then(x => {
      // console.log(x.length);
      if(x.length > 0) {
        // this.progressStatus();
      }
    })
  }


}
